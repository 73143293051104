<template>
  <div>

  </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
      
    }
  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
  },
  created () {
    const venueId = this.currentVenueId;
    this.$router.push(`/${venueId}/bookings-management`)
  },
  mounted () {
    this.isMounted = true
  }
}
</script>
